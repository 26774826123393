import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap'
import config from '../../config'
import Sidebar from './Sidebar/index'
import Content from './Content/index'

class Home extends React.Component {
  state = {
    sites: [],
    activeSourceSite: '',
    products: [],
    loadingProducts: true,
    loadingSites: true
  }
  async componentDidMount() {
    const sites = await this.fetchSites()
    const activeSourceSite = _.get(sites, '0.id')
    this.setState({ sites, activeSourceSite, loadingSites: false })
    const { products, totalProducts } = await this.fetchProducts(activeSourceSite)
    this.setState({ products, totalProducts, loadingProducts: false })
  }
  fetchSites = async () => {
    const url = config.apiUrl + '/sources'
    const response = await axios.get(url)
    return _.get(response, 'data.items')
  }
  fetchProducts = async (sourceSite, page = 0) => {
    const url = `${config.apiUrl}/product_launches?size=10&page=${page}&sourceSite=${sourceSite}`
    const response = await axios.get(url)
    const products = _.get(response, 'data.items')
    const totalProducts = _.get(response, 'data.total')
    return {
      products,
      totalProducts
    }
  }
  loadMoreProducts = async (page) => {
    const { products, activeSourceSite } = this.state
    this.setState({ loadingProducts: true })
    const { products: newProducts, totalProducts } = await this.fetchProducts(activeSourceSite, page)
    this.setState({ products: [...products, ...newProducts], totalProducts, loadingProducts: false })
  }
  onSiteClick = (activeSourceSite) => async () => {
    if (this.state.activeSourceSite !== activeSourceSite) {
      this.setState({ activeSourceSite, loadingProducts: true, products: [] })
      const { products, totalProducts } = await this.fetchProducts(activeSourceSite)
      this.setState({ products, totalProducts, loadingProducts: false })
    }
  }
  render() {
    const { sites, activeSourceSite, products, totalProducts, loadingProducts, loadingSites } = this.state
    return (
      <Container style={{ maxWidth: '1024px' }}>
        <Row noGutters>
          <Col className="no-gutters" sm="4">
            <Sidebar
              items={sites}
              onItemClick={this.onSiteClick}
              activeItemId={activeSourceSite}
              loading={loadingSites}
            />
          </Col>
          <Col className="no-gutters" sm="8">
            <Content
              sourceSite={activeSourceSite}
              items={products}
              loadMore={this.loadMoreProducts}
              loading={loadingProducts}
              totalItems={totalProducts}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Home
