import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { Alert, Button, Badge } from 'reactstrap'
import Card from './Card'
import { colors } from '../../../constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 576px) {
    margin-top: 80px;
  }
`
const DateHeader = styled(Badge)`
  padding: 4px !important;
  top: 72px;
  color: #777 !important;
  background: #eee !important;
  position: sticky;
  width: 120px;
  margin: auto;
  margin-bottom: 12px;
`
const EOLAlert = () => (
  <Alert color="dark" style={{ textAlign: 'center' }}>
    That's all for now!
  </Alert>
)

const Loader = () => (
  <React.Fragment>
    {
      Array.from({ length: 10 }).map((v, i) => {
        return (
          <ContentLoader
            key={i}
            ariaLabel="Loading..."
            width="100%"
            style={{
              marginBottom: '12px',
              marginLeft: '8px',
              height: '100px',
              width: '100%',
              borderRadius: '4px',
              boxShadow: `0 0 2px ${colors.gray300}`
            }}
          >
            <rect x="0" y="0" height="100%" width="100" />
            <rect x="110" y="10" rx="2" ry="6" width="200" height="28" />
            <rect x="110" y="52" rx="2" ry="6" height="16" style={{ width: "calc(100% - 130px)" }} />
            <rect x="110" y="76" rx="2" ry="6" height="16" style={{ width: "calc(100% - 150px)" }} />
          </ContentLoader>
        )
      })
    }
  </React.Fragment>
)

class Content extends React.Component {
  state = {
    page: 0
  }
  componentDidUpdate(prevProps) {
    if (prevProps.sourceSite !== this.props.sourceSite) {
      this.setState({ page: 0 })
    }
  }
  loadMoreItems = async () => {
    const { page } = this.state
    const nextPage = page + 1
    await this.props.loadMore(nextPage)
    this.setState({ page: nextPage })
  }
  insertDateItems = (items) => {
    return _.reduce(items, (acc, item, i) => {
      if (item.type === 'date' || !(item.sourcePublishedAt || item.createdAt)) {
        return acc
      }
      const ts = new Date(item.sourcePublishedAt || item.createdAt)
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ]
      const date = `${monthNames[ts.getMonth()]} ${ts.getDate()}`
      const foundDateItem = _.find(items, { type: 'date', date })
      if (!foundDateItem) {
        acc.splice(i, 0, {
          type: 'date',
          date,
          firstItemId: item.id
        })
      }
      return acc
    }, items)
  }
  renderList = () => {
    const { items, sourceSite, loading } = this.props
    const itemsWithDates = this.insertDateItems(items)
    if (loading && items.length === 0) {
      return <Loader />
    }
    return itemsWithDates.map((data, key) => {
      if (data.type === 'date') {
        return (
          <DateHeader key={key} pill>
            {data.date}
          </DateHeader>
        )
      }
      return (
        <Card {...{ data, key, sourceSite }} />
      )
    })
  }
  renderFooter = () => {
    const { items, totalItems, loading } = this.props
    if (
      _.isArray(items) &&
      _.filter(items, item => item.type !== 'date').length === totalItems
    ) {
      return <EOLAlert />
    }
    return (
      <Button
        onClick={this.loadMoreItems}
        disabled={loading}
        style={{ marginBottom: '20px' }}
      >
        {loading ? 'Loading...' : 'Load More'}
      </Button>
    )
  }
  render() {
    return (
      <Container>
        {this.renderList()}
        {this.renderFooter()}
      </Container>
    )
  }
}

export default Content
