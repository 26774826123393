import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import Item from './Item'

const Container = styled.div`
  position: sticky;
  top: 80px;
  margin-top: 80px;
`
const SiteLinkLoader = () => (
  <ContentLoader
    height="40"
    ariaLabel="Loading..."
    style={{
      width: '100%',
      maxHeight: '40px',
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <rect x="10" y="0" rx="2" ry="6" width="20" height="20" />
    <rect x="40" y="0" rx="2" ry="6" height="20" style={{ width: 'calc(100% - 60px)' }} />
  </ContentLoader>
)
const Loader = () => (
  <React.Fragment>
    {
      Array.from({ length: 5 }).map((v, i) => (
        <SiteLinkLoader key={i} />
      ))
    }
  </React.Fragment>
)

class Sidebar extends React.Component {
  renderList = (items, activeItemId, onItemClick) => {
    return items.map((item, i) => {
      return (
        <Item
          key={i}
          data={item}
          onClick={onItemClick(item.id)}
          active={activeItemId === item.id}
        />
      )
    })
  }
  render() {
    const { items, activeItemId, onItemClick, loading } = this.props
    return (
      <Container>
        {
          loading ? (
            <Loader />
          ) : (
            this.renderList(items, activeItemId, onItemClick)
          )
        }
      </Container>
    )
  }
}

Sidebar.defaultProps = {
  items: [],
  onItemClick: () => {}
}

export default Sidebar
