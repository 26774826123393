import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { App, Home } from './components'
import ga from './ga'

export default (
  <App>
    <Switch>
      <Route exact component={ga(Home)} />
    </Switch>
  </App>
)
