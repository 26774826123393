import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { Alert, Modal, ModalHeader, ModalBody, Button, ModalFooter, Spinner } from 'reactstrap'
import styled, { keyframes } from 'styled-components'
import { MailSend } from 'styled-icons/boxicons-regular/MailSend'
import Formsy from 'formsy-react'
import { EmailInput } from '../common/formsy'
import config from '../../config'
import { colors } from '../../constants'
import { getAxiosErrorData } from '../../utils'

const rotate = keyframes`
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`
const Scale = styled.div`
  display: flex;
  width: fit-content;
  animation: ${rotate} 1s ease-in-out;
`

const styles = {
  header: {
    borderBottom: 0
  },
  body: {
    paddingBottom: 0
  },
  footer: {
    borderTop: 0
  }
}

const defaultState = {
  isOpen: false,
  canSubmit: false,
  formSubmitInProgress: false,
  submitError: '',
  submitSuccess: false
}

class SubscriptionModal extends React.Component {
  state = defaultState

  componentDidMount() {
    const { target } = this.props
    document.getElementById(target).addEventListener('click', (e) => {
      e.preventDefault()
      this.setState(_.assign({}, defaultState, { isOpen: true }))
    })
  }

  enableButton = () => {
    this.setState({ canSubmit: true })
  }

  disableButton = () => {
    this.setState({ canSubmit: false })
  }

  toggle = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  subscribe = async data => {
    try {
      const url = config.apiUrl + '/subscriptions'
      this.setState({ formSubmitInProgress: true })
      await axios.post(url, data)
      this.setState({ formSubmitInProgress: false, submitSuccess: true })
    } catch (err) {
      const res = getAxiosErrorData(err)
      this.setState({
        submitError: _.get(res, 'data.message') || _.get(res, 'message'),
        formSubmitInProgress: false
      })
    }
  }

  renderSuccessModal = () => {
    const { isOpen } = this.state
    return (
      <React.Fragment>
        <ModalHeader toggle={this.toggle} style={styles.header}>
          One Last step...
        </ModalHeader>
        {
          isOpen && (
            <Scale style={{ margin: '0 auto' }}>
              <MailSend size="100px" color={colors.blue} />
            </Scale>
          )
        }
        <ModalBody style={{ textAlign: 'center', fontSize: '20px' }}>
          Confirm your email address!<br />
          <div style={{ fontSize: '14px', marginTop: '10px' }}>
            Click on the confirm subscription button in the email.
            Please check in spam folder if you can't find the mail in your Inbox.
          </div>
        </ModalBody>
        <ModalFooter style={styles.footer}>
          <small style={{ color: colors.gray600, marginRight: 'auto' }}>
            *You can unsubscribe whenever you want.
          </small>
          <Button
            color="secondary"
            type="submit"
            style={{ color: '#fff' }}
            onClick={this.toggle}
          >
            Okay
          </Button>
        </ModalFooter>

      </React.Fragment>
    )
  }

  renderForm = () => {
    const { submitError, formSubmitInProgress, canSubmit } = this.state
    return (
      <React.Fragment>
        <ModalHeader toggle={this.toggle} style={styles.header}>
          Subscribe for latest updates!
        </ModalHeader>
        <Alert color="danger" isOpen={!!submitError}>
          {submitError}
        </Alert>
        <Formsy onValidSubmit={this.subscribe} onValid={this.enableButton} onInvalid={this.disableButton}>
          <ModalBody style={styles.body}>
            <EmailInput
              type="text"
              placeholder="Email Address"
              name="email"
              validations="isEmail"
              validationError="This is not a valid email"
              required
            />
          </ModalBody>
          <ModalFooter style={styles.footer}>
            {
              formSubmitInProgress && (
                <div style={{ marginRight: 'auto', alignSelf: 'flex-end' }}>
                  <Spinner size="sm" /> Processing...
                </div>
              )
            }
            <Button
              color="primary"
              type="submit"
              style={{ color: '#fff' }}
              disabled={!canSubmit || formSubmitInProgress}
            >
              Subscribe
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Formsy>
      </React.Fragment>
    )
  }

  render() {
    const { isOpen, submitSuccess } = this.state
    return (
      <Modal isOpen={isOpen} toggle={this.toggle}>
        {
          submitSuccess ? (
            this.renderSuccessModal()
          ) : (
            this.renderForm()
          )
        }
      </Modal>
    )
  }
}

export default SubscriptionModal
