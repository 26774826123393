import React from 'react'
import ReactGA from 'react-ga'
import config from './config'

ReactGA.initialize(config.gaKey /*, {debug : true}*/)

const tracker = WrappedComponent => {
  const trackPage = page => {
    ReactGA.set({ page })
    ReactGA.pageview(page)
  }

  const HOC = props => {
    const page = props.location.pathname
    // Enable only in production
    if (process.env.NODE_ENV.toLowerCase() === 'production') {
      trackPage(page)
    }
    return <WrappedComponent {...props} />
  }

  return HOC
}

export default tracker
