import { withFormsy } from 'formsy-react'
import React from 'react'
import { FormFeedback, FormGroup, Input } from 'reactstrap'

class EmailInput extends React.Component {
  state = {
    typing: false
  }

  changeValue = e => {
    this.props.setValue(e.currentTarget.value)
    this.setState({
      typing: true
    }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setState({ typing: false })
      }, 1000)
    })
  }

  render() {
    const { typing } = this.state
    const errorMessage = this.props.getErrorMessage()

    return (
      <FormGroup>
        <Input
          onChange={this.changeValue}
          type="text"
          value={this.props.getValue() || ''}
          invalid={!typing && !this.props.isPristine() && !this.props.isValid()}
          placeholder={this.props.placeholder}
        />
        <FormFeedback>{errorMessage}</FormFeedback>
      </FormGroup>
    )
  }
}

export default withFormsy(EmailInput)
