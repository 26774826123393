import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { openInNewTab } from '../../../utils'
import { colors } from '../../../constants'

const Title = styled.div`
  font-size: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`
const Container = styled.div`
  display: flex;
  margin: 0 8px 12px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0 2px ${colors.gray300};
  transition: box-shadow 0.5s;
  user-select: none;
  &:hover {
    box-shadow: 0 3px 10px ${colors.gray400};
  }
`
const Thumbnail = styled.img`
  height: 100px;
  max-width: 100px;
  margin-right: 8px;
`
const Content = styled.div`
  padding: 8px;
`
const Description = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const ThumbLoader = () => (
  <ContentLoader viewBox="0 0 100 100" ariaLabel="Loading..." style={{ maxHeight: '100px', minWidth: '100px' }}>
    <rect x="0" y="0" height="100" width="100" />
  </ContentLoader>
)

const getThumbUrl = (imgUrl, sourceSite) => {
  if (_.isString(imgUrl) && imgUrl.includes('imgix.net') && sourceSite !== 'KICKSTARTER') {
    if (imgUrl.includes('?')) {
      return imgUrl + '&h=100'
    }
    return imgUrl + '?h=100'
  }
  return imgUrl
}

const Card = ({ data = {}, sourceSite }) => {
  const [imgLoading, setImgLoading] = useState(true)
  const { name, summary, description, sourceImageUrl, sourceUrl } = data
  const thumbUrl = getThumbUrl(sourceImageUrl, sourceSite)
  return (
    <Container onClick={() => openInNewTab(sourceUrl)}>
      <Thumbnail
        src={thumbUrl}
        onLoad={() => setImgLoading(false)}
        style={{ display: imgLoading ? 'none' : 'block' }}
      />
      {
        imgLoading && (
          <ThumbLoader />
        )
      }
      <Content>
        <Title>
          {name}
        </Title>
        <Description>
          {summary || description}
        </Description>
      </Content>
    </Container>
  )
}

export default Card
