import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled, { keyframes } from 'styled-components'
import CryOwl from './cry-owl.svg'

const scaleDown = keyframes`
  0% {
    transform: scale(2);
  }
  70% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
`
const ScaleDown = styled.div`
  display: flex;
  width: fit-content;
  animation: ${scaleDown} 1s ease-in-out;
`

const styles = {
  header: {
    borderBottom: 0
  },
  body: {
    paddingBottom: 0
  },
  footer: {
    borderTop: 0
  }
}

class ConfirmEmailModal extends React.Component {
  render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} style={styles.header}>
          Sad to see you go...
        </ModalHeader>
        {
          isOpen && (
            <ScaleDown style={{ margin: '0 auto' }}>
              <img src={CryOwl} style={{ height: '80px' }} alt="crying owl" />
            </ScaleDown>
          )
        }
        <ModalBody style={{ textAlign: 'center', fontSize: '20px' }}>
          You have unsubscribed!<br />
          <div style={{ fontSize: '14px', marginTop: '10px' }}>
            If you unsubscribed by mistake, you can <a href={`/?sub_id=${this.props.subscriptionId}&resubscribe=true`}>click here</a> to subscribe back again
          </div>
        </ModalBody>
        <ModalFooter style={styles.footer}>
          <Button
            color="secondary"
            type="submit"
            style={{ color: '#fff' }}
            onClick={toggle}
          >
            Okay
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ConfirmEmailModal
