/**
 * Returns placeholder image url
 */
export const getPlaceholderImg = config => {
  if (typeof config !== 'object') config = {}
  const width = config.w || 200
  const height = config.h || 100
  const dimensions = `${width}x${height}`
  const text = config.txt || dimensions
  let url = `https://via.placeholder.com/${dimensions}`
  if (typeof config.bg === 'string' && config.bg.length === 6) {
    url += `/${config.bg}`
  }
  if (typeof config.txtclr === 'string' && config.txtclr.length === 6) {
    url += `/${config.txtclr}`
  }
  url += `?text=${text}`
  return url
}

export function openInNewTab(url) {
  const win = window.open(url, '_blank')
  win.focus()
}

export function getAxiosErrorData(error) {
  if (error.response) {
    const { data, status, headers } = error.response
    return {
      data,
      status,
      headers
    }
  } else if (error.request) {
    return {
      message: 'No response received from the server'
    }
  } else {
    const defaultMessage = 'Some unkown error occurred. Please refresh the page and try again'
    const { message = defaultMessage } = error
    return {
      message
    }
  }
}
