import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap'
import styled, { keyframes } from 'styled-components'
import { BadgeCheck } from 'styled-icons/boxicons-solid/BadgeCheck'
import { colors } from '../../constants'

const rotate = keyframes`
  0% {
    transform: rotate(0deg) scale(0);
  }
  70% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`
const RotateAndScale = styled.div`
  display: flex;
  width: fit-content;
  animation: ${rotate} 1s ease-in-out;
`

const styles = {
  header: {
    borderBottom: 0
  },
  body: {
    paddingBottom: 0
  },
  footer: {
    borderTop: 0
  }
}

class ConfirmEmailModal extends React.Component {
  renderSuccessModal = () => {
    const { isOpen, toggle } = this.props
    return (
      <React.Fragment>
        <ModalHeader toggle={toggle} style={styles.header}>
          You've subscribed successfully!
        </ModalHeader>
        {
          isOpen && (
            <RotateAndScale style={{ margin: '0 auto' }}>
              <BadgeCheck size="100px" color={colors.green} />
            </RotateAndScale>
          )
        }
        <ModalBody style={{ textAlign: 'center', fontSize: '20px' }}>
          You're all set! 🙌<br />
          <div style={{ fontSize: '14px', marginTop: '10px' }}>
            We only send occasional emails containing top products 🚀of the week or some important announcements.
          </div>
        </ModalBody>
        <ModalFooter style={styles.footer}>
          <small style={{ color: colors.gray600, marginRight: 'auto' }}>
            *You can unsubscribe whenever you want.
          </small>
          <Button
            color="secondary"
            type="submit"
            style={{ color: '#fff' }}
            onClick={toggle}
          >
            Okay
          </Button>
        </ModalFooter>
      </React.Fragment>
    )
  }

  render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        {
          this.renderSuccessModal()
        }
      </Modal>
    )
  }
}

export default ConfirmEmailModal
