import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import React from 'react'
import styled from 'styled-components'
import config from '../../config'
import Topbar from '../Topbar'
import ConfirmEmailModal from './ConfirmEmailModal'
import UnsubscribedModal from './UnsubscribedModal'

const Container = styled.div`
  height: 100vh;
`

class App extends React.Component {
  state = {
    confirmModalOpen: false,
    unsubscribeModalOpen: false
  }
  async componentDidMount() {
    const query = qs.parse(_.get(document, 'location.search', '').replace('?', ''))
    const subscribeId = _.get(query, 'sub_id')
    if (!!subscribeId) {
      const url = config.apiUrl + '/subscriptions/' + subscribeId + '/confirm_email'
      await axios.post(url)
      this.setState({ confirmModalOpen: true })
      localStorage.setItem('subscriptionId', subscribeId)
    }
    const unsubscribeId = _.get(query, 'unsub_id')
    if (!!unsubscribeId) {
      const url = config.apiUrl + '/subscriptions/' + unsubscribeId + '/unsubscribe'
      await axios.post(url)
      this.setState({ unsubscribeModalOpen: true, subscriptionId: unsubscribeId })
      localStorage.removeItem('subscriptionId')
    }
  }
  toggleConfirmModal = () => {
    const { confirmModalOpen } = this.state
    this.setState({ confirmModalOpen: !confirmModalOpen })
  }
  toggleUnsubscribeModal = () => {
    const { unsubscribeModalOpen } = this.state
    this.setState({ unsubscribeModalOpen: !unsubscribeModalOpen })
  }
  render() {
    const { confirmModalOpen, unsubscribeModalOpen, subscriptionId } = this.state
    return (
      <Container>
        <Topbar />
        {this.props.children}
        <UnsubscribedModal
          isOpen={unsubscribeModalOpen}
          toggle={this.toggleUnsubscribeModal}
          subscriptionId={subscriptionId}
        />
        <ConfirmEmailModal
          isOpen={confirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </Container>
    )
  }
}

export default App
