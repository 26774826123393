import React from 'react'
import { NavbarBrand, Nav, NavItem, Button } from 'reactstrap'
import { Moon } from 'styled-icons/fa-solid/Moon'
import { Sun } from 'styled-icons/icomoon/Sun'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import LogoImg from './owl.svg'
import { themeColors } from '../../constants'
import SubscriptionModal from './SubscriptionModal'
let { Navbar } = require('reactstrap')

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 1024px;
`
const Image = styled.img`
  padding: 0;
  height: 30px;
`
const nightToggleIconCss = css `
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }
`
const SunIcon = styled(Sun)`
  ${nightToggleIconCss};
`
const MoonIcon = styled(Moon)`
  ${nightToggleIconCss};
`
const NightModeToggle = ({ active, onClick }) => {
  let icon = (
    <MoonIcon size="20px" color={themeColors.secondary} title="Toggle Night Mode" style={{ marginTop: '6px' }} />
  )
  if (active) {
    icon = (
      <SunIcon size="20px" color={themeColors.primary} title="Toggle Night Mode" />
    )
  }
  return (
    <div onClick={onClick}>
      {icon}
    </div>
  )
}

Navbar = styled(Navbar)`
  box-shadow: 0 1px 8px #cacaca;
  background-color: white !important;
`

class Topbar extends React.Component {
  state = {
    nightModeActive: false
  }
  toggleNightMode = () => {
    const { nightModeActive } = this.state
    this.setState({ nightModeActive: !nightModeActive })
  }
  render() {
    const { nightModeActive } = this.state
    return (
      <Navbar expand="md" className="fixed-top">
        <Container>
          <Content>
            <NavbarBrand tag="div">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Image src={LogoImg} />
                <small style={{ fontWeight: 'bold', marginLeft: '8px', color: themeColors.dark }}>
                  LAUNCHOWL
                </small>
              </Link>
            </NavbarBrand>
            {/* <Nav className="ml-right" navbar>
              <NavItem>
                <NightModeToggle
                  active={nightModeActive}
                  onClick={this.toggleNightMode}
                />
              </NavItem>
            </Nav> */}
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Button color="secondary" size="sm" id="subscribeModalTrigger">
                  Subscribe
                </Button>
              </NavItem>
            </Nav>
          </Content>
        </Container>
        <SubscriptionModal target="subscribeModalTrigger" />
      </Navbar>
    )
  }
}

export default Topbar
